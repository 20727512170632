import React from 'react';
import mapboxgl from 'mapbox-gl';
import { Link } from "gatsby";
import mapData from './data/wikigreeks_features.json'
import { FaExternalLinkAlt } from 'react-icons/fa'
import '../styles/world-map-of-greeks.css'
import SEO from "../components/seo"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import image from '../images/world-map-of-greeks/world-map-of-greeks-social.png'
import ShareButtons from '../components/ShareButtons/ShareButtons';

class WorldMapOfGreeks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFeature: undefined,
            headerPanelVisible: true
        }
    }

    componentDidMount() {
        document.title = 'The World Map of Greeks';

        // Filter people with not enough pageViews
        mapData.features = mapData.features.filter((feat) => feat.properties.pageViews >= 2000)

        mapboxgl.accessToken = 'pk.eyJ1IjoiamltaWNoYWlsaWRpcyIsImEiOiJjazBlNzZxeWUwZWlkM29yejJ5aWRnaXdhIn0.dklyDYSF16YU0FTyLgJydw';
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            // dark
            style: 'mapbox://styles/jimichailidis/ck66yqgzw0ble1in0fxvgxn7v',
            center: [25, 39],
            zoom: 5
        });

        // map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

        map.on('load', function () {
            map.addSource('people', {
                'type': 'geojson',
                'data': mapData
            })

            map.addLayer({
                "id": "point",
                "source": "people",
                "type": "circle",
                "paint": {
                    "circle-color": "#8685EF",
                    "circle-opacity": 0.5,
                    "circle-radius": {
                        "property": "pageViews",
                        "stops": [
                            [5000, 3],
                            [20000, 5],
                            [50000, 7],
                            [200000, 9]
                        ]
                    }
                }
            });

            map.addLayer({
                "id": "humanLabel",
                "source": "people",
                "type": "symbol",
                "layout": {
                    'text-font': ['Roboto Black', 'Arial Unicode MS Regular'],
                    'text-field': [
                        'format',
                        ['get', 'humanLabel'],
                        { 'font-scale': 0.9 },
                        '\n',
                        {},
                        ['get', 'birthPlaceLabel'],
                        {
                            'font-scale': 0.7,
                            'text-font': [
                                'literal',
                                ['Arial Unicode MS Regular']
                            ]
                        }
                    ],
                    'text-size': {
                        "property": "pageViews",
                        "stops": [
                            [5000, 13],
                            [20000, 14],
                            [50000, 16],
                            [200000, 18]
                        ]
                    },
                    "symbol-z-order": "source",
                    'text-anchor': 'top',
                },
                "paint": {
                    'text-halo-width': 1,
                    'text-halo-blur': 1,
                    'text-halo-color': '#000000',
                    'text-color': '#d3d6d9'
                }
            });
        });

        map.on('mouseenter', 'humanLabel', (e) => {
            this.setState({
                selectedFeature: e.features[0]
            });

            map.getCanvas().style.cursor = 'pointer';

            trackCustomEvent({
                category: 'WorldMapGreeks',
                action: 'SelectedFeature',
                label: this.state.selectedFeature?.properties?.birthPlaceLabel
            });
        });

        map.on('touchend', 'humanLabel', (e) => {
            this.setState({
                selectedFeature: e.features[0]
            });

            map.getCanvas().style.cursor = 'pointer';

            trackCustomEvent({
                category: 'WorldMapGreeks',
                action: 'SelectedFeature',
                label: this.state.selectedFeature?.properties?.birthPlaceLabel
            });
        });

        map.on('mouseleave', 'humanLabel', () => {
            map.getCanvas().style.cursor = "";
        });
    }

    render() {
        return (
            <div className="container peopleMapGreece">
                <SEO title="The World Map of Greeks" description="Every city in the world is represented by its Greek citizen with the most views on wikipedia (in 2019)." pathname='/world-map-of-greeks'
                    image={{ src: image, width: 1200, height: 600 }} />
                {this.state.headerPanelVisible &&
                    <div className='headerPanel'>
                        <h2 style={{ textAlign: 'center'}}> <Link to='/' style={{textDecoration: 'none', color: '#2C71BC'}}><strong>Thousand Words</strong> </Link></h2>
                        <p><strong>The World Map of Greeks.</strong> Every city in the world is represented by its Greek citizen with the most views on wikipedia (in 2019).</p>
                        <ShareButtons url="https://www.thousandwords.blog/world-map-of-greeks"></ShareButtons>
                    </div>
                }

                <div ref={el => this.mapContainer = el} className='mapContainer' />

                {this.state.selectedFeature ?
                    <p className='infoPanel'>
                        <strong>{this.state.selectedFeature.properties.humanLabel} <a href={this.state.selectedFeature.properties.wikiUrl} target='_blank' rel="noopener noreferrer"> <FaExternalLinkAlt /> </a>
                        </strong> <br />
                        <i>{this.state.selectedFeature.properties.humanDescription}, born in {this.state.selectedFeature.properties.birthPlaceLabel} </i>
                    </p>
                    : null
                }
            </div>
        )
    }
}

export default WorldMapOfGreeks;
